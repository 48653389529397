

	@import "./src/assets/styles/color.scss";

	.container {
		padding: 8px;
		.btn.menu {
			display: block;
		}
		h4 {
			font-size: 1rem;
			text-align: left;
		}
		hr {
			border-color: $font;
			border-top-width: 0px;
		}
		ul {
			list-style: none;
			text-align: left;
			padding: 0;
			li {
				font-size: 1rem;
				margin: 16px 0;
				&::after {
					content: " ";
					display: block;
					border-bottom: 1px solid $background;
				}
				& > a {
					padding-bottom: 4px;
					&:focus {
						box-shadow: none !important;
					}
				}
				.input-group .btn {
					background-color: $confirm;
					color: white;
				}
			}
		}
		table {
			width: 100%;
			thead {
				background-color: $tiles;
				color: white;
				tr th{
					padding: 8px 16px;
				}
			}
			tbody {
				tr {
					td {
						padding: 8px 16px;	
					}
					&:nth-child(even) {
						background-color: $background;
					}
				}
			}
		}
		#tritontags {
			table {
				width: 100%;
				border: 1px solid $background;
				margin-bottom: 4rem;
				tr {
					height: 3rem;
					border: 1px solid $background;
				}
				td {
					border: 1px solid $background;
				}
			}
		}
		
	}


@import "./src/assets/styles/color.scss";
ul {
	list-style: none;
}
.yes {
	color: $confirm; 
}
.no {
	color: $error;
}
.custom ul li {
	font-size: 0.8rem;
	margin-bottom: 4px;
}
